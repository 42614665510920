@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


:root {
  --color-accent: #D4AF37;
  --color-text: #242424;
  --color-text-light: #848484;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: var(--color-text);
}

h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 0;
}

h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: var(--color-accent);
  border-bottom: 1px solid var(--color-accent);
  margin-bottom: 0;
}

h3 {
  font-size: 20px;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 16px;
  margin: 0rem;
}

hr {
  width: 40%;
  color: white;
}

p {
  font-size: 16px;
  margin-bottom: 1rem;
  margin-top: 0;
  line-height: 1.5em;
}

p.small {
  font-size: 16px;
  color: var(--color-text-light);
}
ul {
  margin: 0 0 0.5rem;
}

li {
  margin-bottom: 0.5rem;
  line-height: 1.5em;
  font-size: 16px;
}

img {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: background 0.25s, color 0.3s;
  box-sizing: border-box;
}

.header.unscrolled {
  background: transparent;
}

.header.scrolled {
  background: white;
  box-shadow: 0 0 20px #24242425;
}

.hamburger {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s;
}

.hamburger.white {
  color: white;
}

.hamburger.black {
  color: black;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 20;
}

.menu-fade-enter {
  opacity: 0;
}

.menu-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.menu-fade-exit {
  opacity: 1;
}

.menu-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.menu {
  background: white;
  width: 250px;
  height: 100%;
  padding: 2.5rem 1.5rem;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 3rem;
}

.menu nav{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.menu nav ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-left: 0;
}
.menu nav ul li a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  text-align: center;
  text-decoration: none;
  color: var(--color-text);
  transition: all 200ms;
  line-height: 1.5em;
}
.menu nav ul li a:hover {
  color: var(--color-accent);
}

.menu .menu-social {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--color-text);
}

.menu .menu-social a svg path{
  fill: var(--color-text);
  transition: all 200ms;
}

.menu .menu-social a:hover svg path{
  fill: var(--color-accent);
}

.close-menu {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.hero {
  position: relative;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(0.45);
}

.hero-content {
  position: relative;
  text-align: center;
  z-index: 1;
  color: white;
}

content {
  max-width: 1300px;
  margin: auto;
  display: block;
}

content #about,
content #mission,
content #expertise,
content #services {
  padding: 2rem 0 1rem;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 50;
}

.back-to-top:hover {
  background-color: #555;
}

.link-button {
  color: var(--color-accent)!important;
  border: none;
  background: transparent;
  font-size: 16px;
  text-decoration: underline;
  font-family: 'Montserrat';
  font-weight: 400;
  cursor: pointer;
  transition: all 200ms;
  text-align: left;
  padding: 0px;
}

.link-button:hover {
  color: var(--color-text)!important;
}

.contact-button {
  background: var(--color-accent);
  outline: none;
  border: none;
  padding: 0.8rem 1.4rem;
  font-size: 20px;
  color: white;
  letter-spacing: 2px;
  margin-top: 2rem;
  font-family: 'Montserrat';
}

.lang-button {
  padding: 0;
  margin: 0.3rem;
  font-size: 38px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Montserrat';
}

footer {
  margin-top: 1.5rem;
  padding: 1rem;
  text-align: center;
  background: var(--color-text);
}
footer .link-button {
  color: var(--color-accent)!important;
  border: none;
  background: transparent;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Montserrat';
  font-weight: 400;
  cursor: pointer;
  transition: all 200ms;
}

footer .link-button:hover {
  color: white!important;
}

footer .footer-bottom {
  border-top: 1px solid var(--color-accent);
  padding: 0.3rem 0;
  max-width: 1300px;
  margin: 2.5rem auto 0;
}

footer .bottom-menu-social {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
}

footer .bottom-menu-social a svg path{
  fill: var(--color-accent);
  transition: all 200ms;
}

footer .bottom-menu-social a:hover svg path{
  fill: white;
}

footer .footer-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2.5rem;
}

footer .footer-top .bottom-menu{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

footer .footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
}

.terms-enter {
  opacity: 0;
}

.terms-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.terms-exit {
  opacity: 1;
}

.terms-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.terms-popup {
  background: white;
  padding: 2rem;
  max-width: 100%;
  margin: 4rem;
  width: 100%;
  position: relative;
  max-height: calc(90% - 4rem);
  overflow: scroll;
}

.close-terms {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

#expertise .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Deux colonnes de taille égale */
  gap: 20px;
  align-items: center;
}



.grid-item {
  display: flex;
  flex-direction: column;
}

.grid-item-span-12 {
  grid-column: span 12;
}

.grid-item-span-8 {
  grid-column: span 8;
}

.grid-item-span-6 {
  grid-column: span 6;
}

.grid-item-span-4 {
  grid-column: span 4;
}

.grid-item-span-3 {
  grid-column: span 3;
}

.grid-item-span-2 {
  grid-column: span 2;
}

.grid-item-span-1 {
  grid-column: span 1;
}

@media screen and (max-width: 1023px) {
  .grid-item-span-12 {
    grid-column: span 6;
  }
  .grid-item-span-8 {
    grid-column: span 6;
  }
  .grid-item-span-6 {
    grid-column: span 6;
  }
  .grid-item-span-4 {
    grid-column: span 6;
  }
  .grid-item-span-3 {
    grid-column: span 3;
  }
  .grid-item-span-2 {
    grid-column: span 2;
  }
  .grid-item-span-1 {
    grid-column: span 2;
  }
}

@media screen and (max-width: 767px) {
  .grid-item-span-12,
  .grid-item-span-8,
  .grid-item-span-6,
  .grid-item-span-4,
  .grid-item-span-3,
  .grid-item-span-2,
  .grid-item-span-1 {
    grid-column: span 12;
  }
}

.grid-item-span-4 {
  grid-column: span 4;
}

@media screen and (max-width: 1023px) {
  h1 {
    font-size: 40px;
  }
  
  h2 {
    font-size: 22px;
  }

  p {
    font-size: 17px;
    margin-bottom: 0.5rem;
  }
  
  p.small {
    font-size: 15px;
    margin-bottom: 0.5rem;
  }

  .hero {
        height: 650px;
  }

  .grid-container {
    grid-template-columns: repeat(6, 1fr);
  }

  .grid-item-span-4 {
    grid-column: span 6;
  }

  #expertise .grid-container {
    grid-template-columns: 1fr; 
  }

  #expertise .grid-container .image{
    grid-row: 2;
  }

  #expertise .grid-container .texte{
    grid-row: 3;
  }

  content #about,
  content #mission,
  content #expertise,
  content #services {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
  
  h2 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0.7rem;
  }
  
  p.small {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .hero {
        height: 450px;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item-span-4 {
    grid-column: span 12;
  }

  content #about,
  content #mission,
  content #expertise,
  content #services {
    padding: 0.7rem 0;
  }

  .terms-popup {
    background: white;
    padding: 1rem;
    max-width: 100%;
    margin: 15px;
    width: 100%;
    position: relative;
    max-height: calc(100vh - 60px);
    overflow: scroll;
  }

  footer .footer-bottom {
    flex-direction: column;
    justify-content: space-between;
  }
}
